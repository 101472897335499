<template>
  <div v-if="projects && projects.length" class="project-list">
    <ProjectCardComponent v-for="(project, i) in projects" :key="i" :project="project" />
  </div>
</template>

<script>
import ProjectCardComponent from "./ProjectCardComponent.vue";
export default {
  name: "ProjectListComponent",
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ProjectCardComponent,
  },
};
</script>

<style lang="stylus">
.project-list {
  display grid
  grid-gap 40px
  +below(768px) {
    grid-gap 30px
  }
}
</style>
