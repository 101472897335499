<template>
  <main class="projects-page page">
    <BreadcrumbsComponent title="Проектный офис" />
    <div class="container-padding">
      <div class="projects-page__inner page__inner">
        <ProjectListComponent :projects="projects" />
        <PaginationComponent
          v-if="Math.ceil(total / first) > 1"
          :page="page"
          :total="Math.ceil(total / first)"
          @change="reload"
        />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ProjectListComponent from "./components/ProjectListComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import PROJECTS_PAGE from "gql/pages/ProjectsPage.graphql";

export default {
  name: "ProjectsPage",
  async asyncData({ apollo, store }) {
    let variables = {
      first: 4,
      page: 1,
    };
    await apollo.defaultClient
      .query({
        query: PROJECTS_PAGE,
        variables,
      })
      .then(({ data }) => {
        store.dispatch("projects/save", data);
      })
      .catch(() => {});
  },
  data() {
    return {
      page: 1,
      first: 4,
    };
  },
  computed: {
    projects() {
      return this.$store.state.projects.projects_paginate?.data;
    },
    total() {
      return this.$store.state.projects.projects_paginate.paginatorInfo?.total;
    },
  },
  methods: {
    async reload(page = 1) {
      await this.$apolloProvider.defaultClient
        .query({
          query: PROJECTS_PAGE,
          variables: {
            page: page,
            first: this.first,
          },
        })
        .then(({ data }) => {
          this.$store.dispatch("projects/save", data);
          this.page = page;
        });
    },
  },
  components: {
    PaginationComponent,
    ProjectListComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.projects-page {}
</style>
