<template>
  <div v-if="project" class="project-card">
    <ImgComponent :img="project.img" class="project-card__img" />
    <div class="project-card__content">
      <h3 class="project-card__title">{{ project.title }}</h3>
      <div class="project-card__program">
        <div class="project-card__program-text">
          <div class="project-card__program-line" />
          <span class="project-card__program-title">Цель программы: {{ project.target }}</span>
        </div>
        <div class="project-card__program-text">
          <div class="project-card__program-line" />
          <span class="project-card__program-title">Результат: {{ project.result }}</span>
        </div>
      </div>
      <router-link :to="{ name: 'project', params: { id: project.id } }" class="project-card__btn">
        <span>Подробнее</span>
        <IconComponent name="arrow-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ProjectCardComponent",
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    IconComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.project-card {
  display flex
  gap 40px
  +below(1200px) {
    flex-direction column
  }
  +below(768px) {
    gap 20px
  }
  +below(425px) {
    gap 10px
  }

  &__img {
    max-width 680px
    height 450px
    max-height 450px
    +below(1200px) {
      max-width 100%
    }
  }

  &__content {
    max-width 620px
    display flex
    flex-direction column
    grid-gap 30px
    +below(1200px) {
      max-width 100%
    }
    +below(768px) {
      grid-gap 15px
    }
    +below(425px) {
      grid-gap 5px
    }
  }

  &__title {
    +below(425px) {
      font-size 1.5rem
      line-height 36px
    }
    +below(380px) {
      font-size 1.25rem
      line-height 32px
    }
  }

  &__program {
    display flex
    flex-direction column
    grid-gap 20px
    +below(425px) {
      grid-gap 10px
    }
  }

  &__program-text {
    display flex
    gap 15px
  }

  &__program-line {
    background var(--main)
    min-width 51px
    max-width 51px
    height 1px
    margin-top 13px
    +below(425px) {
      min-width 26px
      max-width 26px
    }
  }

  &__program-title {
    +below(425px) {
      font-size .875rem
    }
  }

  &__btn {
    display flex
    align-items center
    gap 14px

    span {
      font-size .875rem
      line-height 22px
    }
  }

}
</style>
